<template>
  <div class="login_box">
    <div class="login_form">
      <img src="~@/assets/images/logo.png" alt="" />
      <el-form :model="formData" :rules="rules" ref="formData">
        <el-form-item label="用户名:" prop="name">
          <i class="iconfont iconyonghuming"></i>
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="密码:" prop="password">
          <i class="iconfont iconmima"></i>
          <el-input v-model="formData.password" type="password"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="loginFn">登录</el-button>
    </div>
  </div>
</template>
<script>
import { login } from '@/api'
import { mapMutations, mapActions } from 'vuex'
import { localGet } from '@/utils/common'
export default {
  name: 'login',
  props: {},
  components: {},
  data () {
    return {
      formData: {
        name: '',
        password: ''
      },
      rules: {
        name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    ...mapMutations(['SET_MENU_LIST', 'SET_LOGIN_USERINFO']),
    ...mapActions(['AREA_LIST']),
    // ...mapActions('im', ['imLogin']),
    loginFn () {
      this.$refs.formData.validate(async valid => {
        if (valid) {
          const res = await login(this.formData)
          if (res.code === '1') {
            const { token, loginUser, permission } = res.data
            localStorage.setItem('token', token)
            localStorage.setItem('businessRole', loginUser.businessRole)
            this.SET_MENU_LIST(permission)
            this.SET_LOGIN_USERINFO(loginUser)
            // 获取省市区存到本地缓存中
            !localGet('areaList') && this.AREA_LIST()
            this.$router.push('/')
            // this.imLogin()
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.login_box {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('~@/assets/images/loginBg.png');
  background-size: 100% 100%;
  .login_form {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 368px;
    height: 320px;
    text-align: center;
  }
}
::v-deep .el-form {
  margin-top: 58px;
  .el-form-item {
    position: relative;
    .el-form-item__label {
      position: absolute;
      left: 14px;
      top: 0;
      color: rgba(255, 255, 255, 0.4);
      &::before {
        color: transparent;
      }
    }
    .el-form-item__content {
      .el-form-item__error {
        top: 110%;
        left: 66px;
      }
      .el-input__inner {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #1890ff;
        background-color: transparent !important;
        padding-left: 80px;
        color: #fff !important;
      }
      .iconfont {
        position: absolute;
        color: #fff;
        font-size: 14px;
        left: 0;
        top: 0;
      }
    }
  }
}
.el-button {
  width: 100%;
  margin-top: 37px;
}
</style>
